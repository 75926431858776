import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { MDXProvider } from '@mdx-js/react';
import ContentDe from '../../content/index.de.mdx';
import ContentEn from '../../content/index.en.mdx';
import { UploadSection } from '../components/upload/UploadSection';
import { mdxComponents } from '../components/layout/MdxPage';


const Home = () => {
    const { language, t } = useI18next([ 'common', 'index' ]);

    const Content = language === 'de'
        ? ContentDe
        : ContentEn;

    return (
        <Layout
            title={t('index:title')}
            seoProps={{
                keywords: t('common:meta.keywords'),
                description: t('common:meta.description'),
            }}
        >
            <UploadSection/>
            <MDXProvider components={mdxComponents}>
                <Content/>
            </MDXProvider>
        </Layout>
    );
};

export default Home;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
