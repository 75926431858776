// extracted by mini-css-extract-plugin
export var border = "DropZone-module--border--164e2";
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var disabled = "DropZone-module--disabled--055cc";
export var dropzone = "DropZone-module--dropzone--30b21";
export var highlighted = "DropZone-module--highlighted--4ef60";
export var icon = "DropZone-module--icon--9fa06";
export var iconError = "DropZone-module--iconError--43f80";
export var iconSuccess = "DropZone-module--iconSuccess--af7b9";
export var iconUpload = "DropZone-module--iconUpload--2ff7f";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";
export var stack = "DropZone-module--stack--bb8e1";
export var title = "DropZone-module--title--f8f0e";
export var uploadIcon = "DropZone-module--uploadIcon--ff9f7";
export var uploadIndicator = "DropZone-module--uploadIndicator--9277e";