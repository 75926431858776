import React, { DragEventHandler, FunctionComponent, PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import * as classes from './DropZone.module.scss';
import { Stack } from '../Stack/Stack';
import { useId } from '../../hooks/useId';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { mergeClasses } from '../../util/style';
import DropFileIcon from '../Icons/drop-file.svg';
import { formatFilesize } from '../../util/files';
import clsx from 'clsx';

interface IDropZoneProps {
    selectedFile: File | null,
    setSelectedFile: (file: File | null) => void,
    disabled?: boolean;
    valid?: boolean;
    maximumSize?: number,
    className?: string,
}

export const DropZone: FunctionComponent<PropsWithChildren<IDropZoneProps>> = (props) => {
    const { t, language } = useI18next();
    const dropZoneId = useId();
    const dropZoneRef = useRef<HTMLDivElement | null>(null);
    const inOutCounter = useRef<number>(0);

    const dropZoneClasses = useMemo(() =>
        mergeClasses(
            classes.dropzone,
            props.className,
            !!props.selectedFile && classes.success,
            !props.valid && classes.error,
            props.disabled && classes.disabled
        )
    , [ props.className, props.selectedFile, props.valid, props.disabled ]
    );

    const dragEnterHandler: DragEventHandler<HTMLDivElement> | undefined = props.disabled
        ? undefined
        : (ev) => {
            ev.preventDefault();
            inOutCounter.current += 1;
            dropZoneRef.current?.classList.toggle(classes.highlighted, inOutCounter.current > 0);
        };

    const dragLeaveHandler: DragEventHandler<HTMLDivElement> | undefined = props.disabled
        ? undefined :
        () => {
            inOutCounter.current -= 1;
            dropZoneRef.current?.classList.toggle(classes.highlighted, inOutCounter.current > 0);
        };

    const dragOverHandler: DragEventHandler<HTMLDivElement> | undefined = props.disabled
        ? undefined
        : (ev) => {
            ev.preventDefault();
        };

    useEffect(() => {
        const dropHandler = (e: DragEvent) => {
            e.preventDefault();
            inOutCounter.current = 0;
            dropZoneRef.current?.classList.remove(classes.highlighted);

            if (!e.dataTransfer) {
                return;
            }

            if (e.dataTransfer.items) {
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    if (e.dataTransfer.items[i].kind === 'file') {
                        props.setSelectedFile(e.dataTransfer?.items[0].getAsFile());
                    }
                }
            } else {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    props.setSelectedFile(e.dataTransfer?.files[0]);
                }
            }
        };

        const dropzoneElement = dropZoneRef.current;

        if (dropzoneElement && !props.disabled) {
            dropzoneElement.addEventListener('drop', dropHandler);
        }

        return () => {
            dropzoneElement?.removeEventListener('drop', dropHandler);
        };
    }, [ props.disabled, props.setSelectedFile ]);

    return <div
        className={dropZoneClasses}
        id={dropZoneId}
        ref={dropZoneRef}
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDragOver={dragOverHandler}
    >
        <svg
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.border}
            focusable={false}
            aria-hidden="true"
        >
            <rect
                width="100%"
                height="100%"
                fill="none"
                rx="8"
                ry="8"
                stroke="currentColor"
            />
        </svg>
        <Stack align="center" className={classes.stack}>
            <DropFileIcon className={clsx(classes.icon, classes.iconUpload)} role="presentation"/>
            <strong className={classes.title}>
                {t('upload:dropZone.title')}
            </strong>
            <span>
                {props.children} {props.maximumSize && t('upload:dropZone.size', { size: formatFilesize(props.maximumSize, language) })}
            </span>
        </Stack>
    </div>;
};
