import React from 'react';
import { Layout } from './Layout';
import * as Stage from './Stage';
import { Section } from './Section/Section';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { LanguageVariantsProvider, useLanguageVariants } from '../../util/i18n';
import { Copy, Headings, Lists } from '../Text';
import { TargetAwareLink } from '../Link';
import TwoColumns from './TwoColumns';
import { Quote } from '../Quote';
import { ProductLogos } from '../Teaser';
import config from '../../../gatsby-config';

const configSiteUrl = config.siteMetadata?.siteUrl as string | undefined;

export const mdxComponents: import('mdx/types').MDXComponents = {
    Section,
    h1: Headings.H1,
    h2: Headings.H2,
    h3: Headings.H3,
    h4: Headings.H4,
    // @ts-ignore
    ul: Lists.Ul,
    // @ts-ignore
    ol: Lists.Ol,
    // @ts-ignore
    a: TargetAwareLink,
    TwoColumns,
    blockquote: Quote,
    wrapper: Copy,
    ProductLogos,
};

export interface IMdxPageProps extends React.PropsWithChildren {
    data: {
        mdx: {
            frontmatter: Record<string, any>,
            fields: {
                language: string,
            }
        },
        languageVersions: {
            edges: Array<{
                node: {
                    fields: {
                        language: string,
                    },
                    frontmatter: {
                        slug: string,
                    }
                }
            }>
        }
    },
}

const LayoutWrapper: React.FunctionComponent<IMdxPageProps> = props => {
    const { setLanguageVariants } = useLanguageVariants();
    const languageVersions = props.data.languageVersions.edges.map(edge => ({
        language: edge.node.fields.language,
        path: `${ configSiteUrl }/${ edge.node.fields.language }/${ edge.node.frontmatter.slug }`,
    }));
    setLanguageVariants(languageVersions);

    return (
        <Layout title={props.data.mdx.frontmatter.title}>
            <Stage.Small title={props.data.mdx.frontmatter.title}/>
            <MDXProvider components={mdxComponents}>
                {props.children}
            </MDXProvider>
        </Layout>
    );
};

const MdxPage: React.FunctionComponent<IMdxPageProps> = props => {
    return (
        <LanguageVariantsProvider>
            <LayoutWrapper {...props}/>
        </LanguageVariantsProvider>
    );
};

export const query = graphql`
    query($id: String!, $language: String!, $baseName: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        mdx(id: { eq: $id }) {
            frontmatter {
                title
            }
        }
        languageVersions: allMdx(filter: {fields:{ baseName: {eq: $baseName}}}) {
            edges {
                node {
                    id
                    fields {
                        language
                    }
                    frontmatter {
                        slug
                    }
                }
            }
        }
    }`;

export default MdxPage;
