import React, { FunctionComponent, PropsWithChildren } from 'react';
import * as classes from './Card.module.scss';
import clsx from 'clsx';

interface ICardProps {
    className?: string;
}

export const Card: FunctionComponent<PropsWithChildren<ICardProps>> = (props) => {
    return <div className={clsx(classes.card, props.className)}>
        {props.children}
    </div>;
};
