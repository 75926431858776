import { useConfig } from '../util/config';
import { useCallback, useEffect, useRef } from 'react';
import { useServiceConsent } from '../util/consent';
import { ServiceId } from '../components/consent';

export interface IUseRecaptchaResponse {
    verify: () => Promise<string>;
}

const id = 'recaptcha-script';

export const useRecaptcha = (): IUseRecaptchaResponse => {
    const { recaptcha: siteKey } = useConfig();
    const [ allowed ] = useServiceConsent(ServiceId.reCaptcha);

    const scriptTag = useRef<HTMLScriptElement | null>(null);

    useEffect(() => {
        if (!allowed || !siteKey || !!document.getElementById(id)) {
            return;
        }

        scriptTag.current = document.createElement('script');
        scriptTag.current.id = id;
        scriptTag.current.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        document.head.appendChild(scriptTag.current);

        return () => {
            if (scriptTag.current) {
                scriptTag.current.remove();
                scriptTag.current = null;
            }
        };
    }, [ siteKey, allowed ]);

    const verify = useCallback<() => Promise<string>>(() => {
        if (!allowed) {
            return Promise.reject();
        }

        return new Promise(res => {
            window.grecaptcha.ready(() => {
                res(window.grecaptcha.execute(siteKey, { action: 'submit' }));
            });
        });
    }, [ siteKey, allowed ]);

    return {
        verify,
    };
};
