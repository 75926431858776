/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner} from "../src/components/Banner";
import {ImageBanner} from "../src/components/ImageBanner";
import {Teaser} from "../src/components/Teaser";
import PacBannerImage from '../src/images/axesCheck_PAC_Banner_illu-03.svg';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    blockquote: "blockquote",
    h3: "h3",
    br: "br",
    a: "a",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Section, ProductLogos} = _components;
  if (!ProductLogos) _missingMdxReference("ProductLogos", false);
  if (!ProductLogos.AxesPdf) _missingMdxReference("ProductLogos.AxesPdf", true);
  if (!ProductLogos.AxesWord) _missingMdxReference("ProductLogos.AxesWord", true);
  if (!Section) _missingMdxReference("Section", true);
  if (!Teaser) _missingMdxReference("Teaser", false);
  if (!Teaser.Card) _missingMdxReference("Teaser.Card", true);
  if (!Teaser.List) _missingMdxReference("Teaser.List", true);
  return React.createElement(React.Fragment, null, React.createElement(ImageBanner, {
    title: "Checking without uploading? No problem with PAC 2024!",
    src: PacBannerImage,
    link: "https://pac.pdf-accessibility.org/en",
    linkText: "Check out PAC 2024"
  }, React.createElement(_components.p, null, "The PDF Accessibility Checker PAC is the extended desktop version of axesCheck (currently only for Windows). It allows you to check your PDF without having to upload it. With PAC you also receive a detailed report and can precisely localise the errors in the document. PAC also offers a screen reader preview and supports the additionally required visual inspection.")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "what-is-axescheck"
  }, "What is axesCheck?"), React.createElement(_components.p, null, "With axesCheck you can quickly and easily check PDF files for accessibility. axesCheck is a web-based version of the PDF Accessibility Checker PAC it will check PDF files to see if they meet the machine-verifiable requirements of PDF/UA (according to the Matterhorn Protocol) and WCAG (A & AA). Additional checks are recommended that only a human can perform - for example, whether the sequence of elements is correct, the tags correspond to the visually recognizable semantics, or alternative texts reflect the meaning of an image.")), "\n", React.createElement(Section, {
    variant: "gray"
  }, React.createElement(_components.h2, {
    id: "what-is-an-accessible-pdf"
  }, "What is an accessible PDF?"), React.createElement(_components.p, null, "To ensure that PDF content is accessible to people with disabilities or can be adapted for different situations (for example, reading on mobile devices), it must be available in an accessible format. There are some basic technical requirements for accessible PDFs that you can easily check with ​axesCheck. For example, an accessible PDF contains an invisible structure layer on which all content-relevant elements are coded in the correct, machine-readable order and important additional information is included for comprehension and targeted navigation.")), "\n", React.createElement(Banner, {
    title: "Do you want us to create your accessible PDFs?",
    link: "https://www.axes4.com/en/products-services/axesservice",
    linkText: "Try axesService"
  }, React.createElement(_components.p, null, "Then send us your file. On demand, fast and with high quality, we create accessible documents for you according to PDF/UA and WCAG - even for extensive projects.")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "why-is-pdf-accessibility-important"
  }, "Why is PDF accessibility important?"), React.createElement(_components.p, null, "Essential information is only available as PDF content. PDF is the most widely used file format worldwide for exchanging documents - be it reports, minutes, tickets or bank statements, for example. However, most PDFs available to date have massive barriers for people with disabilities or are insufficiently prepared for current and future usage scenarios (mobile internet, AI, Big Data, for example). Accessible PDFs solve these issues."), React.createElement(_components.p, null, "What began with clear legal requirements for public administration has now been extended to the private sector and also applies to all PDF content: The European Accessibility Act (EAA) aims to make comprehensive participation in the information society possible for all people."), React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Many people don't know that PDFs actually have to be barrier-free. There are still\nmisunderstandings, e.g. some people say that PDFs are not a website - but it is\nclear, and PDFs must be just as accessible. I would like to clarify that."), "\n", React.createElement(_components.p, null, "--Michael Wahl,\n| Head of the German Federal Monitoring Agency for Accessibility in Information Technology"), "\n")), "\n", React.createElement(Section, {
    variant: "gray"
  }, React.createElement(_components.h2, {
    id: "how-axes4-can-help-you"
  }, "How axes4 can help you"), React.createElement(_components.h3, {
    id: "if-your-pdf-is-not-accessible"
  }, "If your PDF is not accessible"), React.createElement(_components.p, null, "We make your PDF accessible for you through axesService: You send us your PDFs and we make them accessible in the shortest possible time - of course completely PDF/UA and even WCAG compliant (as long as the color contrast of your source files meets the requirements).", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "https://www.axes4.com/en/products-services/axesservice"
  }, "[Try axesService]")), React.createElement(_components.h3, {
    id: "if-you-want-to-create-accessible-pdfs-yourself"
  }, "If you want to create accessible PDFs yourself"), React.createElement(_components.p, null, "We have the right tools and training to help you create accessible PDFs easily, efficiently and sustainably."), React.createElement(Teaser.List, null, React.createElement(Teaser.Card, {
    title: React.createElement(ProductLogos.AxesWord),
    link: "Try axesWord",
    href: "https://www.axes4.com/en/products-services/axesword",
    level: 4
  }, React.createElement(_components.p, null, "With axesWord you can create fully PDF/UA and WCAG compliant PDFs from well-formed Microsoft Word documents with one click only.")), React.createElement(Teaser.Card, {
    title: React.createElement(ProductLogos.AxesPdf),
    link: "Try axesPDF",
    href: "https://www.axes4.com/en/products-services/axespdf",
    level: 4
  }, React.createElement(_components.p, null, "With axesPDF you can find and fix PDF/UA & WCAG errors in PDFs in seconds.")))), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "pdfua---the-iso-standard-for-pdf-accessibility"
  }, "PDF/UA - The ISO Standard for PDF Accessibility"), React.createElement(_components.p, null, "axesCheck can be used to check all machine-verifiable test criteria of the DIN/ISO standard 14289 - known as PDF/UA. UA stands for Universal Access and guarantees the best possible user experience for end users of the most widely used document format in the world."), React.createElement(_components.h3, {
    id: "which-pdfua-requirements-is-axescheck-evaluating"
  }, "Which PDF/UA requirements is axesCheck evaluating?"), React.createElement(_components.h4, {
    id: "basic-requirements"
  }, "Basic requirements"), React.createElement(_components.p, null, "Are the basic rules according to the PDF specification adhered to?"), React.createElement(_components.h4, {
    id: "logical-structure"
  }, "Logical structure"), React.createElement(_components.p, null, "Are there errors in the tag tree that make content inaccessible?"), React.createElement(_components.h4, {
    id: "metadata-and-settings"
  }, "Metadata and settings"), React.createElement(_components.p, null, "Does the PDF have settings that limit accessibility or is it missing important metadata that is required for smooth processing?")), "\n", React.createElement(Section, {
    variant: "gray"
  }, React.createElement(_components.h2, {
    id: "wcag---the-global-guidelines-for-accessible-web-content"
  }, "WCAG - The global guidelines for accessible web content"), React.createElement(_components.p, null, "axesCheck makes it possible to quickly and easily check all machine-verifiable success criteria of the WCAG (Web Content Accessibility Guidelines) Level A and AA for a PDF."), React.createElement(_components.h3, {
    id: "which-wcag-requirements-is-axescheck-evaluating"
  }, "Which WCAG requirements is axesCheck evaluating?"), React.createElement(_components.h4, {
    id: "1-perceivable"
  }, "1 Perceivable"), React.createElement(_components.p, null, "Can all content-relevant elements be perceived? This includes requirements such as:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Are text alternatives available?"), "\n", React.createElement(_components.li, null, "Is the contrast minimum met?"), "\n", React.createElement(_components.li, null, "Is it possible for software to distinguish between content-relevant and decorative elements?"), "\n", React.createElement(_components.li, null, "Is software able to access all content-relevant elements?"), "\n"), React.createElement(_components.h4, {
    id: "2-operable"
  }, "2 Operable"), React.createElement(_components.p, null, "Are information, settings, and mechanisms available to enable navigation and interaction (for example, with form fields)? These include:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Is the title available?"), "\n", React.createElement(_components.li, null, "Is the tab order set for interactive elements?"), "\n"), React.createElement(_components.h4, {
    id: "3-understandable"
  }, "3 Understandable"), React.createElement(_components.p, null, "Can users understand the content and user interface, even if they use voice output, for example? These include:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Are natural languages defined for all elements?"), "\n", React.createElement(_components.li, null, "Is there a Unicode equivalent for each character?"), "\n"), React.createElement(_components.h4, {
    id: "4-robust"
  }, "4 Robust"), React.createElement(_components.p, null, "Are the basic rules according to the PDF specification adhered to?")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
