/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner} from "../src/components/Banner";
import {ImageBanner} from "../src/components/ImageBanner";
import {Teaser} from "../src/components/Teaser";
import PacBannerImage from '../src/images/axesCheck_PAC_Banner_illu-03.svg';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    blockquote: "blockquote",
    h3: "h3",
    br: "br",
    a: "a",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Section, ProductLogos} = _components;
  if (!ProductLogos) _missingMdxReference("ProductLogos", false);
  if (!ProductLogos.AxesPdf) _missingMdxReference("ProductLogos.AxesPdf", true);
  if (!ProductLogos.AxesWord) _missingMdxReference("ProductLogos.AxesWord", true);
  if (!Section) _missingMdxReference("Section", true);
  if (!Teaser) _missingMdxReference("Teaser", false);
  if (!Teaser.Card) _missingMdxReference("Teaser.Card", true);
  if (!Teaser.List) _missingMdxReference("Teaser.List", true);
  return React.createElement(React.Fragment, null, React.createElement(ImageBanner, {
    title: "Prüfen ohne Hochladen? Mit PAC 2024 kein Problem!",
    src: PacBannerImage,
    link: "https://pac.pdf-accessibility.org/de",
    linkText: "Lerne PAC 2024 kennen"
  }, React.createElement(_components.p, null, "Der PDF Accessibility Checker PAC ist die erweiterte Desktop-Version von axesCheck (bisher nur für Windows). Damit kannst du dein PDF prüfen, ohne es hochladen zu müssen. Mit PAC erhältst du zusätzlich einen Detailbericht und kannst die Fehler im Dokument exakt lokalisieren. Außerdem bietet PAC eine Screenreadervorschau und unterstützt die zusätzlich erforderliche Sichtprüfung.")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "was-ist-axescheck"
  }, "Was ist axesCheck?"), React.createElement(_components.p, null, "Mit axesCheck lassen sich PDF-Dateien schnell und einfach auf Barrierefreiheit prüfen. axesCheck ist wie eine webbasierte Version des PDF Accessibility Checkers PAC und prüft für PDF-Dateien, ob diese die maschinell prüfbaren Anforderungen von PDF/UA (gemäß Matterhorn-Protokoll) und WCAG (A & AA) erfüllen. Für eine abschließende Beurteilung sind jedoch weitere Prüfungen erforderlich, die nur ein Mensch vornehmen kann - beispielsweise ob die Reihenfolge der Elemente korrekt ist, die Tags der visuell erkennbaren Semantik entsprechen oder Alternativtexte die Kernaussage eines Bildes angemessen wiedergeben.")), "\n", React.createElement(Section, {
    variant: "gray"
  }, React.createElement(_components.h2, {
    id: "was-ist-ein-barrierefreies-pdf"
  }, "Was ist ein barrierefreies PDF?"), React.createElement(_components.p, null, "Damit PDF-Inhalte auch für Menschen mit Behinderungen zugänglich sind oder sich für unterschiedliche Nutzungssituationen anpassen lassen (beispielsweise fürs Lesen auf Smartphones), müssen diese in barrierefreier Form zur Verfügung stehen. Für barrierefreie PDFs gibt es einige technische Grundanforderungen, die du mit axesCheck spielend prüfen kannst. Beispielsweise enthält ein barrierefreies PDF eine unsichtbare Strukturebene, auf der alle inhaltsrelevanten Elemente in der richtigen Reihenfolge maschinenlesbar kodiert und wichtige Zusatzinformationen für das Verstehen und gezielte Navigieren enthalten sind.")), "\n", React.createElement(Banner, {
    title: "Du willst, dass wir aus deinem PDF eine barrierefreie Datei gemäß PDF/UA und WCAG erstellen?",
    link: "https://www.axes4.com/de/produkte-services/axesservice",
    linkText: "axesService ausprobieren"
  }, React.createElement(_components.p, null, "Dann sende uns deine Datei. On demand, schnell und mit hoher Qualität erstellen wir für dich barrierefreie Dokumente – auch bei umfangreichen Projekten.")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "warum-ist-pdf-barrierefreiheit-wichtig"
  }, "Warum ist PDF-Barrierefreiheit wichtig?"), React.createElement(_components.p, null, "Zum einen liegen viele wesentlichen Informationen ausschließlich als PDF-Inhalte vor. PDF ist das weltweit am häufigsten eingesetzte Dateiformat zum Austausch von Dokumenten - seien es Berichte, Protokolle, Tickets oder Kontoauszüge beispielsweise. Doch die meisten bisher verfügbaren PDFs weisen massive Barrieren für Menschen mit Behinderungen auf oder sind nur unzureichend für aktuelle und zukünftige Nutzungsszenarien gerüstet (Mobiles Internet, KI, Big Data beispielsweise). Barrierefreie PDFs schaffen hier Abhilfe."), React.createElement(_components.p, null, "Zum anderen ist PDF-Barrierefreiheit für viele Organisationen bereits gesetzlich vorgeschrieben. Die Gesetze werden sukzessive auf weitere Bereiche ausgedehnt. Die EU möchte hier eine Vorreiterrolle einnehmen, denn digitale Inklusion ermöglicht eine umfassende Teilhabe aller Menschen an der Informationsgesellschaft."), React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Viele wissen ja nicht, dass PDFs tatsächlich barrierefrei sein müssen.\nDa gibt es immer noch Missverständnisse, z.B. sagen einige,\ndass PDFs ja keine Webseite sind – aber es ist eindeutig,\nund PDFs müssen genauso barrierefrei sein.\nDarüber möchte ich aufklären."), "\n", React.createElement(_components.p, null, "--Michael Wahl,\n| Leiter der Überwachungsstelle des Bundes für Barrierefreiheit in der Informationstechnik"), "\n")), "\n", React.createElement(Section, {
    variant: "gray"
  }, React.createElement(_components.h2, {
    id: "wie-axes4-dich-unterstützt"
  }, "Wie axes4 dich unterstützt"), React.createElement(_components.h3, {
    id: "wenn-dein-pdf-nicht-barrierefrei-ist"
  }, "Wenn dein PDF nicht barrierefrei ist"), React.createElement(_components.p, null, "Wir übernehmen das Erstellen der barrierefreien PDFs für dich. Dazu gibt es unseren axesService: Du schickst uns deine PDFs und wir machen sie in kürzester Zeit barrierefrei - selbstverständlich komplett PDF/UA- und WCAG-konform.", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "https://www.axes4.com/de/produkte-services/axesservice"
  }, "[axesService ausprobieren]")), React.createElement(_components.h3, {
    id: "wenn-du-selbst-barrierefreie-pdfs-erstellen-möchtest"
  }, "Wenn du selbst barrierefreie PDFs erstellen möchtest"), React.createElement(_components.p, null, "Wir haben die richtigen Werkzeuge, mit denen du effizient und nachhaltig selbst barrierefreie PDFs erstellen kannst."), React.createElement(Teaser.List, null, React.createElement(Teaser.Card, {
    title: React.createElement(ProductLogos.AxesWord),
    link: "axesWord ausprobieren",
    href: "https://www.axes4.com/de/produkte-services/axesword",
    level: 4
  }, React.createElement(_components.p, null, "Mit axesWord kannst du aus Microsoft Word mit einem Klick PDF/UA- und WCAG-konforme PDFs erstellen.")), React.createElement(Teaser.Card, {
    title: React.createElement(ProductLogos.AxesPdf),
    link: "axesPDF ausprobieren",
    href: "https://www.axes4.com/de/produkte-services/axespdf",
    level: 4
  }, React.createElement(_components.p, null, "Mit axesPDF kannst du PDF/UA-& WCAG-Fehler in PDFs in Sekundenschnelle finden und beheben."))), React.createElement(_components.h2, {
    id: "axes4-barrierefreies-pdf---einfach-erledigt"
  }, "axes4: Barrierefreies PDF - einfach erledigt!"), React.createElement(_components.p, null, "Wir sind ein Software- und Serviceunternehmen, das sich ganz auf den Bereich PDF-Barrierefreiheit spezialisiert hat. Unsere Lösungen und Dienstleistungen nutzen Regierungsstellen und Unternehmen in der ganzen Welt. Unsere große Mission ist es, das Erstellen barrierefreier Inhalte selbstverständlich werden zu lassen. Wir freuen uns, wenn wir auch dich dabei unterstützen dürfen. Werde ein a11y, der sich für digitale Inklusion einsetzt und sie auch umsetzt!")), "\n", React.createElement(Section, null, React.createElement(_components.h2, {
    id: "pdfua---der-iso-standard-für-pdf-barrierefreiheit"
  }, "PDF/UA - Der ISO-Standard für PDF-Barrierefreiheit"), React.createElement(_components.p, null, "Mit axesCheck lassen sich alle maschinenüberprüfbaren Testkriterien des DIN-/ISO-Standards 14289 - bekannt als PDF/UA - überprüfen. UA steht für Universal Access und garantiert die bestmögliche User Experience für Endanwender des am häufigsten genutzten Dokumentformats weltweit."), React.createElement(_components.h3, {
    id: "was-prüft-axescheck-hier"
  }, "Was prüft axesCheck hier?"), React.createElement(_components.h4, {
    id: "basisanforderungen"
  }, "Basisanforderungen"), React.createElement(_components.p, null, "Sind die grundlegenden Regeln gemäß der PDF-Spezifikation eingehalten?"), React.createElement(_components.h4, {
    id: "logische-struktur"
  }, "Logische Struktur"), React.createElement(_components.p, null, "Gibt es Fehler im Tag-Baum, die Inhalte unzugänglich machen?"), React.createElement(_components.h4, {
    id: "metadaten-und-einstellungen"
  }, "Metadaten und Einstellungen"), React.createElement(_components.p, null, "Weist das PDF Einstellungen auf, welche die Barrierefreiheit einschränken oder fehlen wichtige Metadaten, die für eine problemlose Verarbeitung erforderlich sind?")), "\n", React.createElement(Section, {
    variant: "gray"
  }, React.createElement(_components.h2, {
    id: "wcag---die-weltweit-geltenden-richtlinien-für-barrierefreie-webinhalte"
  }, "WCAG - Die weltweit geltenden Richtlinien für barrierefreie Webinhalte"), React.createElement(_components.p, null, "axesCheck macht es möglich, bei einem PDF alle maschinenüberprüfbaren Erfolgskriterien der WCAG (Web Content Accessibility Guidelines) schnell und einfach zu überprüfen."), React.createElement(_components.h3, {
    id: "was-prüft-axescheck-hier-1"
  }, "Was prüft axesCheck hier?"), React.createElement(_components.h4, {
    id: "1-wahrnehmbar"
  }, "1 Wahrnehmbar"), React.createElement(_components.p, null, "Können alle inhaltsrelevanten Elemente wahrgenommen werden? Dazu zählen Anforderungen wie:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Sind Textalternativen vorhanden?"), "\n", React.createElement(_components.li, null, "Ist das Kontrastminimum eingehalten?"), "\n", React.createElement(_components.li, null, "Kann Software zwischen inhaltsrelevanten und dekorativen Elementen unterscheiden?"), "\n", React.createElement(_components.li, null, "Kann Software auf alle inhaltsrelevanten Elemente zugreifen?"), "\n"), React.createElement(_components.h4, {
    id: "2-bedienbar"
  }, "2 Bedienbar"), React.createElement(_components.p, null, "Sind Informationen, Einstellungen und Mechanismen verfügbar, die das Navigieren und Interagieren (beispielsweise bei Formularfeldern) ermöglichen? Dazu zählen:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Ist der Titel verfügbar?"), "\n", React.createElement(_components.li, null, "Ist die Tab-Reihenfolge für interaktive Elemente festgelegt?"), "\n"), React.createElement(_components.h4, {
    id: "3-verständlich"
  }, "3 Verständlich"), React.createElement(_components.p, null, "Können Benutzer die Inhalte und die Bedienoberfläche verstehen, auch wenn sie beispielsweise eine Sprachausgabe verwenden? Dazu zählen:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Sind für alle Elemente die natürliche Sprache festgelegt?"), "\n", React.createElement(_components.li, null, "Gibt es für jedes Zeichen eine Unicode-Entsprechung?"), "\n"), React.createElement(_components.h4, {
    id: "4-robust"
  }, "4 Robust"), React.createElement(_components.p, null, "Sind die grundlegenden Regeln gemäß der PDF-Spezifikation eingehalten?")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
