// extracted by mini-css-extract-plugin
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var content = "Banner-module--content--465cf";
export var contentContainer = "Banner-module--content-container--dcd14";
export var contentWrapper = "Banner-module--content-wrapper--79d82";
export var linkWrapper = "Banner-module--link-wrapper--08a67";
export var section = "Banner-module--section--4be6b";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";
export var title = "Banner-module--title--08ca5";