import React from 'react';
import { Button } from '../Button';
import { PageWidth } from '../layout/PageWidth';
import * as classes from './Banner.module.scss';

export interface IBannerProps extends React.PropsWithChildren {
    title: string;
    link?: string;
    linkText?: string;
}

export const Banner: React.FunctionComponent<IBannerProps> = props => {
    return (
        <section className={classes.section}>
            <PageWidth>
                <div className={classes.contentWrapper}>
                    <h2 className={classes.title}>{props.title}</h2>
                    <div className={classes.content}>
                        {(!!props.link && !!props.linkText) ? (
                            <>
                                <div className={classes.contentContainer}>{props.children}</div>
                                <div className={classes.linkWrapper}>
                                    <Button
                                        as="a"
                                        href={props.link}
                                        rel="noopener"
                                    >
                                        {props.linkText}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div>{props.children}</div>
                        )}
                    </div>
                </div>
            </PageWidth>
        </section>
    );
};
