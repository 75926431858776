import React from 'react';
import { Spinner, SpinnerSize } from '../loading';
import { Stack } from '../Stack/Stack';

export const UploadIndicator: React.FunctionComponent<{className?: string, label: string}> = props => {
    return (
        <Stack align="center" justify="center" className={props.className}>
            <Spinner size={SpinnerSize.big} label={props.label}/>
        </Stack>
    );
};
