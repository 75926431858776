import React from 'react';
import { Section, SectionVariant } from '../layout';
import { Card, CardSection } from '../Card';
import { UploadForm } from './UploadForm';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { ApplicationTitle } from '../layout/ApplicationTitle';
import * as classes from './UploadSection.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

export const UploadSection: React.FunctionComponent = () => {
    const { t } = useI18next([ 'common', 'upload' ]);

    return (
        <Section variant={SectionVariant.DarkGreen}>
            <Card className={classes.container}>
                <div className={classes.sidebar} aria-hidden="true">
                    <StaticImage
                        src="../../images/image001.jpg"
                        alt={t('upload:imageDescription')}
                        className={classes.image}
                        height={680}
                        quality={90}
                        placeholder="blurred"
                        objectFit="cover"
                    />
                </div>
                <CardSection className={classes.content}>
                    <ApplicationTitle title={t('index:title')}/>
                    <UploadForm/>
                </CardSection>
            </Card>
        </Section>
    );
};
