import React, { FunctionComponent, PropsWithChildren } from 'react';
import * as classes from '../Card.module.scss';
import clsx from 'clsx';

interface ISectionProps {
    className?: string;
}

export const Section: FunctionComponent<PropsWithChildren<ISectionProps>> = (props) => {

    return <div className={clsx(classes.section, props.className)}>
        {props.children}
    </div>;
};
