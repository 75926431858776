// extracted by mini-css-extract-plugin
export var attribute = "UploadForm-module--attribute--9a470";
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var description = "UploadForm-module--description--c2ce0";
export var dropZone = "UploadForm-module--dropZone--13d84";
export var dropZoneContainer = "UploadForm-module--dropZoneContainer--ebdbe";
export var fieldset = "UploadForm-module--fieldset--fc6a0";
export var fileInfo = "UploadForm-module--fileInfo--f90b8";
export var fileInputTrigger = "UploadForm-module--file-input-trigger--b64d8";
export var filenameInput = "UploadForm-module--filename-input--40f7f";
export var filenameInputLabel = "UploadForm-module--filename-input-label--3c9e8";
export var filenameInputWrapper = "UploadForm-module--filename-input-wrapper--732c8";
export var footer = "UploadForm-module--footer--3d83a";
export var heading = "UploadForm-module--heading--9c8e0";
export var info = "UploadForm-module--info--5da46";
export var privacy = "UploadForm-module--privacy--f90ca";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";
export var statusMessage = "UploadForm-module--statusMessage--90dec";
export var submit = "UploadForm-module--submit--7f868";
export var title = "UploadForm-module--title--c89f3";
export var uploadIndicator = "UploadForm-module--upload-indicator--7ad23";