import React from 'react';
import * as classes from './ImageBanner.module.scss';
import { PageWidth } from '../layout/PageWidth';
import { Button } from '../Button';

export interface IImageBannerProps extends React.PropsWithChildren {
    title: string;
    src: string;
    alt?: string;
    link?: string;
    linkText?: string;
}

export const ImageBanner: React.FunctionComponent<IImageBannerProps> = props => (
    <section className={classes.section}>
        <PageWidth>
            <div className={classes.contentWrapper}>
                <h2 className={classes.title}>{props.title}</h2>
                <img src={props.src} alt={props.alt ?? ''} className={classes.image} />
                <div className={classes.content}>
                    <div>{props.children}</div>
                    {(!!props.link && !!props.linkText) && (
                        <div className={classes.linkWrapper}>
                            <Button
                                as="a"
                                href={props.link}
                                rel="noopener"
                            >
                                {props.linkText}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </PageWidth>
    </section>
);
