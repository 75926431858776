// extracted by mini-css-extract-plugin
export var breakpointXs = "550px";
export var container = "UploadSection-module--container--b6c7a";
export var content = "UploadSection-module--content--e672b";
export var image = "UploadSection-module--image--9523f";
export var sidebar = "UploadSection-module--sidebar--616c0";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";