import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { BusyButton, Button } from '../Button';
import { uploadFile } from '../../util/api';
import { useId } from '../../hooks/useId';
import { useReportData } from '../../util/report-data';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { linkStyles } from '../Link';
import * as classes from './UploadForm.module.scss';
import { DropZone } from '../DropZone/DropZone';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ErrorResponse, TestSummary } from '../../types/api';
import { useConfig } from '../../util/config';
import { formatFilesize, matchesAccept } from '../../util/files';
import { Link } from 'gatsby';
import { UploadIndicator } from './UploadIndicator';
import { sleep } from '../../util/functions';
import { Copy } from '../Text';
import { useServiceConsent } from '../../util/consent';
import { ServiceId } from '../consent';
import { StatusMessage, StatusMessageType } from '../StatusMessage/StatusMessage';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { Checkbox } from '../Checkbox/Checkbox';

export interface IUploadFormProps {
    disabled?: boolean;
    allowed?: boolean;
}

const privacyComponents = {
    // @ts-ignore missing to attribute set from translation
    a: <Link {...linkStyles} />,
    br: <br/>,
};

const trackingEventCategory = 'uploadForm';

const devOptions = {
    stubUpload: false,
    uploadId: '702f1f4f-9f22-42a1-ad55-de1e987aeeee',
    uploadDuration: 7000,
    redirect: true,
};

export const UploadForm: React.FunctionComponent<IUploadFormProps> = (props) => {
    const { trackEvent } = useMatomo();
    const { verify } = useRecaptcha();
    const { t, navigate, language } = useI18next();
    const [ busy, setBusy ] = useState(false);
    const { addSummary } = useReportData();
    const [ selectedFile, setSelectedFile ] = useState<File | null>(null);
    const fileNameId = useId();
    const fileInputId = useId();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [ acceptedPrivacy, setAcceptedPrivacy ] = useState(false);
    const [ , setStatus ] = useServiceConsent(ServiceId.reCaptcha);

    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ fileError, setFileError ] = useState(false);
    const errorMessageId = useId();

    const { uploadOptions, maintenance } = useConfig();
    const matchesFileType = useCallback(matchesAccept(uploadOptions.type), [ uploadOptions ]);

    const getFileValidationMessage = useCallback((file?: File | null): string | null => {
        if (!file) {
            return null;
        }

        if (!matchesFileType(file)) {
            return t('upload:error.fileType');
        }

        if (file.size > uploadOptions.size) {
            return t('upload:error.fileSize', { size: formatFilesize(uploadOptions.size, language) });
        }

        return null;
    }, [ matchesFileType, uploadOptions.size ]);

    const fileValidationMessage = useMemo(() => getFileValidationMessage(selectedFile), [ getFileValidationMessage, selectedFile ]);

    const handleFileDrop = useCallback((file: File | null) => {
        const validationMessage = getFileValidationMessage(file);
        if (!validationMessage) {
            setSelectedFile(file);
            setError(undefined);
            setFileError(false);
            if (file) {
                trackEvent({
                    category: trackingEventCategory,
                    action: 'selectFileDrop',
                });
            }
        } else {
            setError(validationMessage);
            setFileError(true);
            setSelectedFile(null);
            trackEvent({
                category: trackingEventCategory,
                action: 'invalidFileDrop',
            });
        }
    }, [ trackEvent, setSelectedFile, getFileValidationMessage ]);

    const handleFileChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files.item(0);
            const validationMessage = getFileValidationMessage(file);
            if (!validationMessage) {
                setSelectedFile(file);
                setError(undefined);
                setFileError(false);
                trackEvent({
                    category: trackingEventCategory,
                    action: 'selectFile',
                });
            } else {
                setError(validationMessage);
                setFileError(true);
                setSelectedFile(null);
                trackEvent({
                    category: trackingEventCategory,
                    action: 'invalidFile',
                });
            }
        }
    }, [ setSelectedFile, trackEvent, getFileValidationMessage ]);

    const isValid = fileValidationMessage === null && acceptedPrivacy && !!selectedFile;

    const handleSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();

        if (!isValid || maintenance) {
            return;
        }

        if (!fileInputRef.current) {
            return;
        }

        if (!selectedFile) {
            setError(t('upload:error.noFile'));
            setFileError(true);
            return;
        }

        if (fileValidationMessage) {
            setError(fileValidationMessage);
            setFileError(true);
            return;
        }

        if (!acceptedPrivacy) {
            setError(t('upload:error.privacy'));
            return;
        }

        const reCaptchaToken = await verify();

        setBusy(true);

        setError(undefined);
        setFileError(false);

        trackEvent({
            category: trackingEventCategory,
            action: 'submitFile',
        });

        const doUpload = devOptions.stubUpload
            ? sleep(devOptions.uploadDuration)
                .then(() => ({ body: { jobId: devOptions.uploadId } }) as TestSummary)
            : uploadFile(selectedFile, language, reCaptchaToken)
                .then(summary => {
                    if (summary) {
                        addSummary(summary.body.jobId, summary);
                        trackEvent({
                            category: trackingEventCategory,
                            action: 'uploadSuccess',
                        });
                    }
                    return summary;
                });

        doUpload
            .then(summary => {
                if (summary && (devOptions.redirect || !devOptions.stubUpload)) {
                    navigate(`/result/${ summary.body.jobId }`);
                }
            })
            .catch(e => {
                const errorCode = ((e as ErrorResponse).code) ?? undefined;
                const errorMessage = t(`upload:error.${ errorCode ?? 'fallback' }`);
                setError(errorMessage);
                trackEvent({
                    category: trackingEventCategory,
                    action: 'uploadError',
                    value: errorCode,
                });
                console.error(e);
            })
            .then(() => {
                setBusy(false);
            });
    }, [ selectedFile, acceptedPrivacy, verify, language, addSummary, navigate, trackEvent, isValid ]);

    const handleAcceptedPrivacyChange = useCallback<(isSelected: boolean) => void>((isSelected) => {
        setAcceptedPrivacy(isSelected);
        setStatus(isSelected);
    }, [ setStatus, setAcceptedPrivacy ]);

    const focusFileInput = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
        fileInputRef.current?.click();
    }, []);

    const dropZoneComponents = useMemo(() => ({
        button: (
            <button
                onClick={focusFileInput}
                type="button"
                aria-describedby={error ? errorMessageId : undefined}
                {...linkStyles}
            />
        ),
    }), [ focusFileInput, error, errorMessageId ]);

    const fileName = selectedFile?.name ?? '-';

    return (
        <form method="POST" onSubmit={handleSubmit}>
            <fieldset className={classes.fieldset}>
                <legend className={classes.title}>
                    <h2 className={classes.heading}>{t('upload:title')}</h2>
                </legend>
                {!busy && (
                    <div className={classes.dropZoneContainer}>
                        <Copy as="p" className={classes.description}>
                            <Trans
                                ns="upload"
                                i18nKey="description"
                                components={privacyComponents}
                            />
                        </Copy>
                        <DropZone
                            selectedFile={selectedFile}
                            setSelectedFile={handleFileDrop}
                            maximumSize={uploadOptions.size}
                            className={classes.dropZone}
                            valid={!fileError}
                            disabled={props.disabled}
                        >
                            <span>
                                {!props.disabled ? <Trans
                                    ns="upload"
                                    i18nKey="dropZone.select"
                                    components={dropZoneComponents}
                                /> : null}
                            </span>
                        </DropZone>
                        <Copy className={classes.description}>
                            <label
                                htmlFor={fileNameId}
                                className={classes.filenameInputLabel}
                            >
                                {t('upload:fileName')}
                            </label>
                            <div
                                className={classes.filenameInputWrapper}
                                data-placeholdersize={fileName}
                            >
                                <textarea
                                    id={fileNameId}
                                    className={classes.filenameInput}
                                    readOnly
                                    value={fileName}
                                    rows={1}
                                />
                            </div>
                        </Copy>
                        <div className={classes.info}>
                            <VisuallyHidden>
                                <label htmlFor={fileInputId}>File</label>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    id={fileInputId}
                                    onChange={handleFileChange}
                                    tabIndex={-1}
                                    aria-hidden="true"
                                    accept={uploadOptions.type}
                                    disabled={props.disabled}
                                />
                            </VisuallyHidden>
                            <Button
                                onClick={focusFileInput}
                                type="button"
                                className={classes.fileInputTrigger}
                                aria-describedby={error ? errorMessageId : undefined}
                                disabled={props.disabled}
                            >
                                {t('upload:selectFile')}
                            </Button>
                        </div>
                    </div>
                )}
                {(error === undefined && !busy) && (
                    <div className={classes.privacy}>
                        <Checkbox isSelected={acceptedPrivacy} onChange={handleAcceptedPrivacyChange}>
                            <Trans
                                ns="upload"
                                i18nKey="privacy"
                                components={privacyComponents}
                            />
                        </Checkbox>
                    </div>
                )}
                {busy && (
                    <UploadIndicator
                        label={t('upload:checking')}
                        className={classes.uploadIndicator}
                    />
                )}
                <div className={classes.footer}>
                    {error !== undefined && (
                        <div aria-live="polite" id={errorMessageId}>
                            <StatusMessage
                                className={classes.statusMessage}
                                title={<strong>{t('upload:error.title')}</strong>}
                                status={StatusMessageType.error}
                            >
                                <strong>{t('upload:error.type')}</strong> {error}
                            </StatusMessage>
                        </div>
                    )}
                    {maintenance && (
                        <StatusMessage
                            className={classes.statusMessage}
                            title={<strong>{t('upload:maintenance.title')}</strong>}
                            status={StatusMessageType.error}
                        >
                            {t('upload:maintenance.text')}
                        </StatusMessage>
                    )}
                    <BusyButton
                        type="submit"
                        disabled={busy || props.disabled}
                        busy={busy}
                        timeout={750}
                        aria-disabled={busy || !isValid || props.disabled || maintenance}
                        aria-describedby={error ? errorMessageId : undefined}
                        className={classes.submit}
                    >
                        {t('upload:submit')}
                    </BusyButton>
                </div>
            </fieldset>
        </form>
    );
};
