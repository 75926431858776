// extracted by mini-css-extract-plugin
export var breakpointXs = "550px";
export var colorGray = "#8e9287";
export var colorGrayDark = "#616161";
export var colorGrayDarker = "#272824";
export var colorGrayLight = "#e3e4e2";
export var colorGrayLighter = "#f2f3f2";
export var colorPrimary = "#a6ce3c";
export var colorSecondary = "#4f7802";
export var content = "ImageBanner-module--content--212e7";
export var contentContainer = "ImageBanner-module--content-container--45726";
export var contentWrapper = "ImageBanner-module--content-wrapper--01420";
export var image = "ImageBanner-module--image--243b4";
export var section = "ImageBanner-module--section--682fb";
export var spacingLg = "32px";
export var spacingMd = "32px";
export var spacingSm = "16px";
export var spacingXl = "128px";
export var spacingXs = "8px";
export var spacingXxs = "4px";
export var title = "ImageBanner-module--title--db1c5";